<template>
  <div>
    <vLoading
      :active="isLoading"
      :can-cancel="true"
      :on-cancel="onCancel"
      :is-full-page="true"
    />
    <h1 class="text-center text-3xl">User Data</h1>
    <!-- Search user -->
    <div class="text-sm">※ Please enter at least 3 letters</div>
    <div class="relative mb-4 flex flex-wrap items-stretch mx-auto">
      <input
        type="text"
        class="relative m-0 -mr-0.5 block w-[1px] min-w-0 flex-auto rounded-l border border-solid border-gray-300 bg-transparent bg-clip-padding px-3 py-[0.25rem] text-base font-normal leading-[1.6] text-neutral-700 outline-none transition duration-200 ease-in-out focus:z-[3] focus:border-blue-500 focus:text-gray-700 focus:shadow-[inset_0_0_0_1px_rgb(59,113,202)] focus:outline-none"
        placeholder="Search User"
        v-model.trim="email"
        @keyup.enter="handleSubmit"
      />
      <button
        class="z-[2] inline-block rounded-r bg-blue-500 px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:z-[3] focus:bg-blue-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-blue-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)]"
        @click="handleSubmit"
        type="button"
      >
        <BootstrapIcon icon="search" size="lg" />
      </button>
    </div>
    <button
      v-if="userDataList?.UserInfoList?.length"
      type="button"
      class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm p-2.5 text-center inline-flex items-center mr-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
      @click="isShow = !isShow"
    >
      <svg
        class="w-5 h-5 ease-in duration-300"
        :class="{ 'rotate-90': !isShow }"
        aria-hidden="true"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 14 10"
      >
        <path
          stroke="currentColor"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M1 5h12m0 0L9 1m4 4L9 9"
        />
      </svg>
      <span class="ml-1" v-if="!isShow"> 展開使用者列表</span>
      <span class="ml-1" v-else> 收起使用者列表</span>
      <span class="sr-only">Icon description</span>
    </button>

    <div
      class="userList my-4"
      :class="{ active: isShow }"
      v-if="userDataList?.UserInfoList?.length"
    >
      <div class="relative overflow-x-auto">
        <table
          class="w-full text-sm text-left text-gray-500 dark:text-gray-400"
        >
          <thead
            class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400"
          >
            <tr>
              <th scope="col" class="px-6 py-3">Email</th>
              <th scope="col" class="px-6 py-3">Family Name</th>
              <th scope="col" class="px-6 py-3">Name</th>
              <th scope="col" class="px-6 py-3">UserID</th>
            </tr>
          </thead>
          <tbody>
            <tr
              class="bg-white relative border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
              v-for="(user, index) in userDataList.UserInfoList"
              :class="{
                'border-gray-300 border-b-2 border-dashed':
                  (index + 1) % 60 === 0,
              }"
              :key="user.Email"
            >
              <td
                scope="row"
                class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
              >
                <a
                  class="font-medium text-blue-600 dark:text-blue-500 hover:underline"
                  href="#"
                  @click.prevent="getTargetEmail(user)"
                  >{{ user.Email }}</a
                >
              </td>
              <td class="px-6 py-4">
                {{ user.FamilyName }}
              </td>
              <td class="px-6 py-4">
                {{ user.Name }}
              </td>
              <td class="px-6 py-4">
                {{ user.CognitoIdentityId }}
              </td>
            </tr>
          </tbody>
        </table>
        <div class="flex justify-center items-center text-gray-400">
          <button
            class="btn !flex flex-col justify-center items-center"
            type="button"
            @click="getMoreUser"
            v-if="this.userDataList.NextToken"
          >
            <span>See more</span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="rgba(156, 163, 175)"
            >
              <polygon
                points="12 17.414 3.293 8.707 4.707 7.293 12 14.586 19.293 7.293 20.707 8.707 12 17.414"
              />
            </svg>
          </button>
        </div>
      </div>
    </div>
    <div v-else>no data</div>
    <!-- Search user -->

    <div class="top" v-if="userData">
      <h2 class="h2 text-2xl">User Information</h2>
      <transition>
        <div class="userinfo" v-show="userInfoActive">
          <table id="UserData_pc">
            <tr>
              <td>Cognito Identity</td>
              <td colspan="4">{{ userData.CognitoIdentityId }}</td>
            </tr>
            <tr>
              <td>Email</td>
              <td>
                {{ userData.Email }}
              </td>
              <td>Locale</td>
              <td>{{ userData.Locale }}</td>
            </tr>
            <tr>
              <td>Name</td>
              <td>{{ userData.Name }}</td>
              <td>Family Name</td>
              <td>{{ userData.FamilyName }}</td>
            </tr>
            <tr>
              <td>QA mode</td>
              <td>
                {{ userData.EnableQAMode ? 'Enabled' : 'Disabled' }}
                <router-link
                  :to="'/qa_mode/' + userData.Email"
                  class="px-3 py-2 text-xs font-medium text-center
                  text-white bg-green-400 rounded-lg hover:bg-green-500
                  focus:ring-4 focus:outline-none focus:ring-blue-300
                 "
                >
                  Switch QA mode
                </router-link>
              </td>
              <td>QA API Endpoint</td>
              <td>{{ userData.ApiEndpoint }}</td>
            </tr>
            <tr>
              <td>Host Cognito Id</td>
              <td>
                {{
                  userData.HostCognitoIdentityId === userData.CognitoIdentityId
                    ? userData.HostCognitoIdentityId
                    : HostUser?.HostCognitoIdentityId
                }}
              </td>
              <td>Host Email</td>
              <td>
                {{
                  userData.HostCognitoIdentityId !== userData.CognitoIdentityId
                    ? HostUser?.HostEmail
                    : ''
                }}
              </td>
            </tr>
            <tr>
              <td>Account Status</td>
              <td>
                {{ userData.Enabled ? 'Enabled' : 'Disabled' }} /
                {{ userData.UserStatus }}
              </td>
              <td>Initialized User</td>
              <td>{{ userData.InitializedUser }}</td>
            </tr>
            <tr>
              <td>Creation Date</td>
              <td>
                {{ userData.UserCreateDate }}
              </td>
              <td>Last Modified</td>
              <td>{{ userData.UserLastModifiedDate }}</td>
            </tr>
          </table>

          <table id="UserData_m">
            <tr>
              <td>Cognito Identity</td>
              <td>{{ userData.CognitoIdentityId }}</td>
            </tr>
            <tr>
              <td>Email</td>
              <td>{{ userData.Email }}</td>
            </tr>
            <tr>
              <td>Locale</td>
              <td>{{ userData.Locale }}</td>
            </tr>
            <tr>
              <td>Name</td>
              <td>{{ userData.Name }}</td>
            </tr>
            <tr>
              <td>Family Name</td>
              <td>{{ userData.FamilyName }}</td>
            </tr>
            <tr>
              <td>QA mode</td>
              <td>{{ userData.EnableQAMode ? 'Enabled' : 'Disabled' }}</td>
            </tr>
            <tr>
              <td>QA API Endpoint</td>
              <td>{{ userData.ApiEndpoint }}</td>
            </tr>
            <tr>
              <td>Host Cognito Id</td>
              <td>{{ userData.HostCognitoIdentityId }}</td>
            </tr>
            <tr>
              <td>Host Email</td>
              <td>{{ userData.HostEmail }}</td>
            </tr>
            <tr>
              <td>Account Status</td>
              <td>
                {{ userData.Enabled ? 'Enabled' : 'Disabled' }} /
                {{ userData.UserStatus }}
              </td>
            </tr>
            <tr>
              <td>Initialized User</td>
              <td>{{ userData.InitializedUser }}</td>
            </tr>
            <tr>
              <td>Creation Date</td>
              <td>
                {{ userData.UserCreateDate }}
              </td>
            </tr>
            <tr>
              <td>Last Modified</td>
              <td>{{ userData.UserLastModifiedDate }}</td>
            </tr>
          </table>
        </div>
      </transition>
    </div>

    <div style="padding: 20px" v-if="this.subUser.length">
      <h2 class="text-2xl h2">Family Member</h2>
      <div class="subUser">
        <table>
          <tr>
            <th>Email</th>
            <th>Name</th>
            <th>Member ID</th>
            <th>Creation Date(+8)</th>
            <th>Last Modified(+8)</th>
          </tr>
          <tr v-for="(item, index) of this.subUser" :key="'subUser' + index">
            <td>{{ item.SubUserEmail }}</td>
            <td>{{ item.SubUserFirstName + ' ' + item.SubUserLastName }}</td>
            <td>{{ item.UserID }}</td>
            <td>{{ item.Creation }}</td>
            <td>{{ item.Update }}</td>
          </tr>
        </table>
      </div>
    </div>

    <div style="padding: 20px">
      <h2 class="h2" v-if="userData">Device Relation</h2>
      <div id="container" v-if="userData">
        <div class="region" id="region">
          <ul class="regionList">
            <li>Region</li>
            <li
              v-for="(item, index) in regionData"
              :key="'region' + index"
              :id="item.CompID.replace('region#', 'region-')"
              name="region"
            >
              {{ item.RegionName }}
              <div class="regionInfo">
                <h3>Region：{{ item.RegionName }}</h3>
                <p
                  :key="'rInfo' + index"
                  v-for="(it, index) in item.ThingNames"
                >
                  {{ this.deviceName[it] }}
                </p>
              </div>
            </li>
            <li class="nodata" v-show="regionData.length === 0">no Region</li>
          </ul>
        </div>

        <div class="left" id="device">
          <ul>
            <li>Device</li>
            <li
              class="item"
              v-for="(item, index) in getDeviceData"
              :key="'Device' + index"
              :id="item.CompID.replace('dev#', 'dev-')"
              name="source"
              @click="DeviceDataHandle(item.CompID)"
            >
              {{ item.DeviceName }}
            </li>
            <li class="nodata" v-show="getDeviceData.length === 0">
              no Device
            </li>
          </ul>
        </div>

        <div class="right" id="group">
          <ul class="groupList">
            <li>Group</li>
            <li
              v-for="(item, index) in groupData"
              :key="'group' + index"
              :id="item.CompID.replace('group#', 'group-')"
              name="target"
            >
              {{ item.GroupName }}
              <div class="groupInfo">
                <h3>Group：{{ item.GroupName }}</h3>
                <p
                  :key="'gInfo' + index"
                  v-for="(it, index) in item.ThingNames"
                >
                  {{ this.deviceName[it] }}
                </p>
              </div>
            </li>

            <li class="nodata" v-show="groupData.length === 0">no Group</li>
          </ul>
        </div>
      </div>
    </div>

    <template v-if="MobileData?.ios?.length && MobileData?.android?.length">
      <h2 class="h2 text-2xl pl-5">Mobile</h2>
      <div class="mobileDiv">
        <div class="mobileTable" v-if="MobileData.ios.length > 0">
          <table class="me-1 text-center">
            <tr>
              <td colspan="4" class="">
                <img
                  class="DeviceType mx-auto"
                  src="../assets/images/apple.svg"
                />
              </td>
            </tr>
            <tr>
              <td width="25%">Sns Enable</td>
              <td width="25%">App Version</td>
              <td width="25%">Device Model</td>
              <td width="25%">Update Date</td>
            </tr>
            <tr v-for="(item, index) of MobileData.ios" :key="'mobI' + index">
              <td>{{ item.SnsEnable }}</td>
              <td>{{ item.AppVersion }}</td>
              <td>{{ item.DeviceModel }}</td>
              <td>{{ item.UpdateDate }}</td>
            </tr>
          </table>
        </div>

        <div class="mobileTable" v-if="MobileData.android.length > 0">
          <table class="ms-1 text-center">
            <tr>
              <td colspan="4">
                <img
                  class="DeviceType mx-auto"
                  src="../assets/images/android.svg"
                />
              </td>
            </tr>
            <tr>
              <td width="25%">Sns Enable</td>
              <td width="25%">App Version</td>
              <td width="25%">Device Model</td>
              <td width="25%">Update Date</td>
            </tr>
            <tr
              v-for="(item, index) of MobileData.android"
              :key="'mobA' + index"
            >
              <td>{{ item.SnsEnable }}</td>
              <td>{{ item.AppVersion }}</td>
              <td>{{ item.DeviceModel }}</td>
              <td>{{ item.UpdateDate }}</td>
            </tr>
          </table>
        </div>
      </div>
    </template>

    <!-- The Modal -->
    <div id="myModal" class="modal" v-show="show">
      <!-- Modal content -->
      <div class="modal-content">
        <span class="close" @click="closeHandler">&times;</span>
        <div class="modaltop" v-if="this.deviceData">
          <h2>
            {{ this.deviceData.DeviceName }}
          </h2>
          <div
            style="
              display: flex;
              justify-content: space-between;
              align-items: center;
            "
          >
            <h3># Device Information</h3>
            <button
              class="showMap"
              @click="this.showMap = !this.showMap"
              role="button"
            >
              {{ this.showMap ? 'Close Map' : 'Show Map' }}
            </button>
          </div>
          <section class="userinfo mb-2">
            <table id="Device_pc">
              <tr>
                <td>
                  <button
                    class="checkstatus"
                    :disabled="!checkDeviceStatus"
                    @click="mqttCheckDeviceStatus"
                  >
                    Check Status
                  </button>
                </td>
                <td colspan="5">
                  <div class="statusDiv" v-if="mqttResponseStatus != null">
                    <template v-if="mqttResponseStatus == 'online'">
                      <span class="status online"></span>
                      <span class="text"
                        >ONLINE <span>{{ this.mqttPingElapseMs }} ms</span>
                      </span>
                    </template>
                    <template v-else>
                      <span class="status offline"></span>
                      <span class="text">OFFLINE</span>
                    </template>
                  </div>
                </td>
              </tr>
              <tr>
                <td>User Id</td>
                <td colspan="5">{{ deviceData.ThingName }}</td>
              </tr>
              <tr>
                <td>Mac Address</td>
                <td colspan="5">{{ deviceData.macAddress }}</td>
              </tr>
              <tr>
                <td>Device Type</td>
                <td>{{ deviceData.DType }}</td>
                <td>Firmware Id</td>
                <td>{{ deviceData.FId }}</td>
                <td>Firmware Version</td>
                <td>{{ deviceData.FirmwareVersion }}</td>
              </tr>
              <tr>
                <td>Time Zone</td>
                <td>{{ deviceData.TimeZone }}</td>
                <td>Google Home</td>
                <td>{{ ShadowInfo.googleHome }}</td>
                <td>Enable QA Mode</td>
                <td>{{ ShadowInfo.enableQAMode }}</td>
                <td>ADR</td>
                <td>{{ ShadowInfo.adr }}</td>
              </tr>
              <tr>
                <td>Creation Date(+8)</td>
                <td>{{ deviceData.Creation }}</td>
                <td>Last Modified(+8)</td>
                <td>{{ deviceData.Update }}</td>
                <td>Last Access Time(+8)</td>
                <td>{{ deviceData.LastAccessTime }}</td>
              </tr>
            </table>

            <table id="Device_m">
              <tr>
                <td><button class="checkstatus">Check Status</button></td>
                <td colspan="2">
                  <div class="statusDiv">
                    <span class="status online"></span>
                    <span class="text">ONLINE <span>500 ms</span> </span>
                  </div>
                </td>
              </tr>
              <tr>
                <td width="50%">Thing Name</td>
                <td>{{ this.deviceData.ThingName }}</td>
              </tr>
              <tr>
                <td>Mac Address</td>
                <td colspan="2">{{ this.deviceData.macAddress }}</td>
              </tr>
              <tr>
                <td>Device Type</td>
                <td>{{ this.deviceData.DeviceType }}</td>
              </tr>
              <tr>
                <td>Firmware Id</td>
                <td>{{ this.deviceData.FirmwareId }}</td>
              </tr>
              <tr>
                <td>Firmware Version</td>
                <td>{{ this.deviceData.FirmwareVersion }}</td>
              </tr>
              <tr>
                <td>Time Zone</td>
                <td>{{ this.deviceData.TimeZone }}</td>
              </tr>
              <tr>
                <td>Google Home</td>
                <td>{{ this.ShadowInfo.googleHome }}</td>
              </tr>
              <tr>
                <td>Enable QA Mode</td>
                <td>{{ this.ShadowInfo.enableQAMode }}</td>
              </tr>
              <tr>
                <td>Creation Date</td>
                <td>
                  {{ this.deviceData.Creation }}
                </td>
              </tr>
              <tr>
                <td>Last Modified</td>
                <td>{{ this.deviceData.Update }}</td>
              </tr>
              <tr>
                <td>Last Access Time</td>
                <td>{{ this.deviceData.LastAccessTime }}</td>
              </tr>
            </table>
          </section>

          <!-- 初始化地圖設定 -->
          <div id="map" v-if="this.showMap">
            <l-map ref="myMap" :zoom="zoom" :center="center" :options="options">
              <!-- 載入圖資 -->
              <l-tile-layer :url="url" :attribution="attribution" />
              <!-- 創建標記點 -->
              <l-marker ref="marker" :lat-lng="this.deviceData.location">
                <!-- 彈出視窗 -->
                <l-popup ref="popup">
                  {{ this.deviceData.DeviceName }}
                </l-popup>
              </l-marker>
            </l-map>
          </div>
          <h3 class="h3"># Notification</h3>
          <section class="shadow mb-2">
            <div class="shadowinfo mb-2">
              <table id="Notification">
                <tr v-if="this.ShadowInfo.CleanNotification !== undefined">
                  <td>Clean Filter Notification</td>
                  <td>
                    <BootstrapIcon
                      :variant="
                        this.ShadowInfo.CleanNotification ? 'check-green' : ''
                      "
                      :icon="this.ShadowInfo.CleanNotification ? 'check' : 'x'"
                      size="2x"
                    ></BootstrapIcon>
                  </td>
                </tr>
                <tr
                  v-if="
                    this.ShadowInfo.CleanSecondaryFilterNotification !==
                      undefined
                  "
                >
                  <td>Clean Secondary Filter</td>
                  <td>
                    <BootstrapIcon
                      :variant="
                        this.ShadowInfo.CleanSecondaryFilterNotification
                          ? 'check-green'
                          : ''
                      "
                      :icon="
                        this.ShadowInfo.CleanSecondaryFilterNotification
                          ? 'check'
                          : 'x'
                      "
                      size="2x"
                    ></BootstrapIcon>
                  </td>
                </tr>
                <tr
                  v-if="this.ShadowInfo.CleanFilterNotification !== undefined"
                >
                  <td>Clean Filter Notification</td>
                  <td>
                    <BootstrapIcon
                      :variant="
                        this.ShadowInfo.CleanFilterNotification
                          ? 'check-green'
                          : ''
                      "
                      :icon="
                        this.ShadowInfo.CleanFilterNotification ? 'check' : 'x'
                      "
                      size="2x"
                    ></BootstrapIcon>
                  </td>
                </tr>
                <tr v-if="this.ShadowInfo.TankFullNotification !== undefined">
                  <td>TankFull Notification</td>
                  <td>
                    <BootstrapIcon
                      :variant="
                        this.ShadowInfo.TankFullNotification
                          ? 'check-green'
                          : ''
                      "
                      :icon="
                        this.ShadowInfo.TankFullNotification ? 'check' : 'x'
                      "
                      size="2x"
                    ></BootstrapIcon>
                  </td>
                </tr>
                <tr
                  v-if="this.ShadowInfo.FrontFilterNotification !== undefined"
                >
                  <td>Front Filter Notification</td>
                  <td>
                    <BootstrapIcon
                      :variant="
                        this.ShadowInfo.FrontFilterNotification
                          ? 'check-green'
                          : ''
                      "
                      :icon="
                        this.ShadowInfo.FrontFilterNotification ? 'check' : 'x'
                      "
                      size="2x"
                    ></BootstrapIcon>
                  </td>
                </tr>
                <tr v-if="this.ShadowInfo.Pm25FilterNotification !== undefined">
                  <td>Pm2.5 Filter Notification</td>
                  <td>
                    <BootstrapIcon
                      :variant="
                        this.ShadowInfo.Pm25FilterNotification
                          ? 'check-green'
                          : ''
                      "
                      :icon="
                        this.ShadowInfo.Pm25FilterNotification ? 'check' : 'x'
                      "
                      size="2x"
                    ></BootstrapIcon>
                  </td>
                </tr>
              </table>
            </div>
            <h3># Schedule</h3>
            <section class="schedule">
              <table>
                <tr>
                  <td width="20%"></td>
                  <td>Mon</td>
                  <td>Tue</td>
                  <td>Wed</td>
                  <td>Thu</td>
                  <td>Fri</td>
                  <td>Sat</td>
                  <td>Sun</td>
                  <td width="25%">time(+8)</td>
                  <td width="25%">action</td>
                </tr>
                <tr v-for="(item, index) of ShadowData" :key="'shadow' + index">
                  <td width="10%">Schedule {{ index + 1 }}</td>
                  <td v-for="it in 7" :key="'it' + it">
                    <BootstrapIcon
                      :variant="item.days.includes(it) ? item.color : ''"
                      :icon="item.icon"
                      size="2x"
                    />
                  </td>
                  <td>{{ item.time }}</td>
                  <td>{{ item.action === 1 ? 'Open' : 'Close' }}</td>
                </tr>
                <tr v-if="this.ShadowData.length === 0">
                  <td colspan="10">no data</td>
                </tr>
              </table>
            </section>
          </section>

          <h3 class="h3 mb-4"># Data Usage</h3>
          <section class="mb-10">
            <div class="flex flex-col">
              <div class="w-1/2">
                <div class="flex items-center mb-1">
                  <label class="pl-4 mr-4">Start Time (UTC)</label>
                  <flat-pickr
                    v-model="UsageStime"
                    :config="flatPickrConfig"
                  ></flat-pickr>
                </div>
              </div>
              <div class="w-1/2">
                <div class="flex items-center">
                  <label class="pl-4 mr-4">End Time (UTC)</label>
                  <flat-pickr
                    v-model="UsageEtime"
                    :config="flatPickrConfig"
                  ></flat-pickr>
                  <button
                    class="bg-gray-500 px-4 py-2 rounded-lg text-white mx-3"
                    type="button"
                    @click="this.handleAlarm"
                  >
                    送出
                  </button>
                </div>
              </div>
            </div>

            <div class="w-1/2">
              <table class="mt-6 w-full border border-collapse">
                <tr
                  v-for="(item, index) of dataUsage"
                  :key="'dataUsage' + index"
                >
                  <td class="p-3 border">{{ index }}</td>
                  <td class="border text-center">{{ item }}</td>
                </tr>
              </table>
            </div>
          </section>

          <h3 v-show="false"># MQTT Message Logs</h3>
          <section class="search" v-show="false">
            <label
              >Start Time (UTC+0)
              <flat-pickr
                v-model="stime"
                :config="flatPickrConfig"
              ></flat-pickr>
            </label>

            <label
              >End Time (UTC+0)
              <flat-pickr
                v-model="etime"
                :config="flatPickrConfig"
              ></flat-pickr>
            </label>

            <label
              >Limit
              <input type="text" value="50" />
            </label>
            <button>search</button>
          </section>

          <div class="flex items-center mb-6">
            <button
              class="showOTA"
              @click="this.showOTA = !this.showOTA"
              role="button"
            >
              {{ this.showOTA ? 'Close OTA' : 'Show OTA' }}
            </button>
            <button
              class="showMQTTLog"
              @click="this.showMQTTLog = !this.showMQTTLog"
              role="button"
            >
              {{ this.showMQTTLog ? 'Close MQTT Logs' : 'Show MQTT Logs' }}
            </button>
          </div>
          <div id="OTA" class="mb-6" v-if="this.showOTA">
            <h3 class="h3"># OTA</h3>
            Target OTA path:
            <input
              type="text"
              class="otainput"
              v-model.trim="targetOTAPath"
              size="100"
              placeholder="fw-ota-test2.s3.ap-northeast-1.amazonaws.com"
            />&nbsp;
            <button class="checkstatus" @click="sendOTACommand">送出</button>
          </div>
          <div id="MQTTLog" v-if="this.showMQTTLog">
            <h3 class="h3"># MQTT logs</h3>
            <ul class="logs">
              <li
                class="border mb-3"
                v-for="(item, index) in mqttLogs"
                v-bind:key="item.time"
              >
                <div class="bg-gray-200 py-3 text-center relative">
                  <p>{{ item.topic }}</p>
                  <p>{{ item.time }}</p>
                  <div
                    class="w-6 h-6 bg-yellow-500 text-white absolute left-0 top-0"
                  >
                    {{ index + 1 }}
                  </div>
                </div>
                <pre class="p-5">{{ item.payload }}</pre>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>

    <!-- page top -->
    <transition name="fade">
      <div
        id="pagetop"
        class="fixed right-0 bottom-0"
        v-show="scY > 300"
        @click="toTop"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="35"
          height="35"
          viewBox="0 0 24 24"
          fill="none"
          stroke="#4a5568"
          stroke-width="1"
          stroke-linecap="square"
          stroke-linejoin="arcs"
        >
          <path d="M18 15l-6-6-6 6" />
        </svg>
      </div>
    </transition>
  </div>
</template>

<script>
import moment from 'moment';

import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';
import 'flatpickr/dist/themes/material_green.css';
import BootstrapIcon from '@dvuckovic/vue3-bootstrap-icons';

import 'leaflet/dist/leaflet.css';
import { LMap, LTileLayer, LMarker, LPopup } from '@vue-leaflet/vue-leaflet';
import { mapActions, mapGetters } from 'vuex';
import { Icon } from 'leaflet';
delete Icon.Default.prototype._getIconUrl;
Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});

import AWSIoTData from 'aws-iot-device-sdk';
import cnf from '@/config';
import https from '@/helpers/https';
import { sleep } from '@/helpers';

export default {
  name: 'linkElementModal',
  components: { BootstrapIcon, flatPickr, LMap, LTileLayer, LMarker, LPopup },
  data() {
    return {
      userData: null,
      firmwareIdMapping: ['', 8720, 8721, 8195],
      zoom: 15,
      center: [],
      url: 'https://tile.openstreetmap.org/{z}/{x}/{y}.png',
      attribution: `© <a href="http://osm.org/copyright">OpenStreetMap</a> contributors`,
      options: {
        zoomControl: false,
      },
      stime: '',
      etime: '',
      flatPickrConfig: {
        enableTime: true,
        time_24hr: true,
        altFormat: 'Z',
        altInput: true,
        dateFormat: 'U',
        minDate: new Date().fp_incr(-15),
      },
      scTimer: 0,
      scY: 0,
      showMap: false,
      showMQTTLog: false,
      showOTA: false,
      deviceData: null,
      userInfoActive: true,
      email: '',
      userid: '',
      show: false,
      isShow: true,
      jsPlumb: null, // 快取範例化的jsplumb物件
      common: {
        PaintStyle: { stroke: '#999999', strokeWidth: 1 },
      },
      mqttClient: null,
      mqttClientId:
        'GoogleHomeAdmin-DKIoTConsole' + Math.floor(Math.random() * 100000 + 1),
      mqttThingName: '',
      mqttPingElapseStartMs: 0,
      mqttPingElapseMs: 0,
      mqttTimeoutId: null,
      mqttResponseStatus: null,
      mqttLogs: [],
      targetOTAPath: null,
      sendOTAprocessing: false,
      UsageEtime: '',
      UsageStime: '',
      errors: [],
      dataUsage: [],
    };
  },
  watch: {
    mqttCredentialsData: function(val) {
      if (this.mqttClient) {
        this.mqttClient.updateWebSocketCredentials(
          val.AccessKeyId,
          val.SecretKey,
          val.SessionToken
        );
      } else {
        this.mqttConnect();
        this.mqttClient.updateWebSocketCredentials(
          val.AccessKeyId,
          val.SecretKey,
          val.SessionToken
        );
      }
    },
    userPermissionsData: function(val) {
      if (val && val['Auth'] && val['Auth'].includes('mqtt_credentials')) {
        this.getMqttCredentials();
      }
    },
    deviceData: async function(val) {
      if (
        this.userPermissionsData &&
        this.userPermissionsData['Auth'] &&
        this.userPermissionsData['Auth'].includes('mqtt_credentials') &&
        val === null
      ) {
        clearTimeout(this.mqttTimeoutId);
        this.mqttUnsubscribe();
        this.mqttResponseStatus = null;
      }
    },
    userData: async function(val) {
      if (val) {
        document.querySelectorAll('.jtk-connector').forEach((e) => e.remove());
        this.userid = val.CognitoIdentityId;
        if (val.HostCognitoIdentityId !== val.CognitoIdentityId) {
          await Promise.all([
            this.getGroup(val.CognitoIdentityId),
            this.getDevice(val.CognitoIdentityId),
            this.getRegion(val.CognitoIdentityId),
            this.getMobile(val.CognitoIdentityId),
            this.getSubuser(val.CognitoIdentityId),
            this.getHostUser(val.CognitoIdentityId),
          ]);
        } else {
          await Promise.all([
            this.getGroup(val.CognitoIdentityId),
            this.getDevice(val.CognitoIdentityId),
            this.getRegion(val.CognitoIdentityId),
            this.getMobile(val.CognitoIdentityId),
            this.getSubuser(val.CognitoIdentityId),
          ]);
        }

        this.showPlumb();
      }
    },
  },
  methods: {
    mqttConnect() {
      const that = this;
      that.mqttClient = AWSIoTData.device({
        region: cnf.mqtt.region,
        host: cnf.mqtt.host,
        clientId: that.mqttClientId,
        protocol: 'wss',
        maximumReconnectTimeMs: 1800000,
        debug: false,
        accessKeyId: '',
        secretKey: '',
        sessionToken: '',
      });

      that.mqttClient.on('connect', function() {
        console.log('connect');
      });
      that.mqttClient.on('reconnect', function() {
        console.log('reconnect');
      });
      that.mqttClient.on('message', function(topic, payload) {
        const payload_obj = JSON.parse(payload);
        that.mqttLogs.unshift({
          topic: topic,
          time: new Date(),
          payload: payload_obj,
        });
        if (
          topic == that.mqttSubscribeTopicRegResp &&
          !payload_obj.Error &&
          payload_obj.FirmwareVersion
        ) {
          that.mqttPingElapseMs = Date.now() - that.mqttPingElapseStartMs;
          that.mqttResponseStatus = 'online';
          that.deviceData = {
            ...that.deviceData,
            FId: that.firmwareIdMapping[payload_obj.FirmwareId],
            FirmwareVersion: payload_obj.FirmwareVersion,
          };
        }
      });
    },
    mqttCheckDeviceStatus() {
      const that = this;
      that.mqttResponseStatus = null;
      that.mqttPingElapseMs = 0;
      clearTimeout(that.mqttTimeoutId);
      console.log(that.mqttPublishTopicRegReq);
      that.mqttPingElapseStartMs = Date.now();
      that.mqttClient.publish(
        that.mqttPublishTopicRegReq,
        JSON.stringify({ Timestamp: Math.round(new Date() / 1000) })
      );
      that.mqttTimeoutId = setTimeout(function() {
        if (that.mqttPingElapseMs === 0) {
          that.mqttResponseStatus = 'offline';
          that.deviceData = {
            ...that.deviceData,
            FId: 'offline',
            FirmwareVersion: 'offline',
          };
        }
      }, 10000);
    },
    mqttSubscribe(mqttThingName) {
      const that = this;
      console.log(that.userData.EnableQAMode);
      console.log(that.mqttSubscribeTopic);
      that.mqttResponseStatus = null;
      that.mqttThingName = mqttThingName;
      that.mqttClient.subscribe(that.mqttSubscribeTopic);
      console.log('mqttSubscribe', that.mqttSubscribeTopic);
    },
    mqttUnsubscribe() {
      const that = this;
      that.mqttClient.unsubscribe(that.mqttSubscribeTopic);
      console.log('mqttUnsubscribe', that.mqttSubscribeTopic);
    },
    showPlumb() {
      this.jsPlumb = this.$jsPlumb.getInstance({
        Container: 'container', // 選擇器id
        EndpointStyle: { radius: 0.11, fill: '#999' }, // 端點樣式
        PaintStyle: this.common.PaintStyle,
        HoverPaintStyle: { stroke: '#ff0000', strokeWidth: 2 }, // 預設懸停樣式  預設為null
        Connector: ['Straight'], // 要使用的預設聯結器的型別：直線，折線，曲線等
      });

      for (let index in this.groupData) {
        for (let ii in this.groupData[index].ThingNames) {
          this.jsPlumb.connect(
            {
              source: 'dev-' + this.groupData[index].ThingNames[ii],
              target: this.groupData[index].CompID.replace('group#', 'group-'),
              anchor: ['Left', 'Right'],
            },
            this.common
          );
        }
      }

      for (let index in this.regionData) {
        for (let ii in this.regionData[index].ThingNames) {
          this.jsPlumb.connect(
            {
              source: 'dev-' + this.regionData[index].ThingNames[ii],
              target: this.regionData[index].CompID.replace(
                'region#',
                'region-'
              ),
              anchor: ['Right', 'Left'],
            },
            this.common
          );
        }
      }
    },
    //  初始化規則使其可以連線、拖拽
    initLeaf(id, type) {
      // console.log(type)
      const ins = this.jsPlumb;
      const elem = document.getElementById(id);
      if (type === 'source') {
        // makeTarget 不能手動連線，若要手動連線請用makeSource
        ins.makeTarget(elem, {
          anchor: [1, 0.5, 0, 0], // 左 上 右 下
          allowLoopback: false, //允許回連
          maxConnections: -1, //最大連線數（-1表示不限制）
        });
      } else {
        ins.makeTarget(elem, {
          anchor: [0, 0.5, 0, 0],
          allowLoopback: false,
          maxConnections: -1,
        });
      }
    },
    setjsPlumb(sourceFlag, targetFlag, regionFlag) {
      const source = document.getElementsByName('source');
      const target = document.getElementsByName('target');
      const region = document.getElementsByName('region');
      this.jsPlumb.setSourceEnabled(source, sourceFlag);
      this.jsPlumb.setTargetEnabled(target, targetFlag);
      this.jsPlumb.setTargetEnabled(region, regionFlag);
      this.jsPlumb.setDraggable(source, false); // 是否支援拖拽
      this.jsPlumb.setDraggable(target, false); // 是否支援拖拽
      this.jsPlumb.setDraggable(region, false); // 是否支援拖拽
    },
    repaintPlumb() {
      // 重繪連線
      this.jsPlumb.repaintEverything();
    },
    ...mapActions({
      onCancel: 'cancelPromise',
      searchUser: 'searchUser',
      getDevice: 'getDevice',
      getGroup: 'getGroup',
      getRegion: 'getRegion',
      getDeviceShadow: 'getDeviceShadow',
      getMobile: 'getMobile',
      getSubuser: 'getSubuser',
      getMqttCredentials: 'getMqttCredentials',
      getHostUser: 'getHostUser',
    }),
    getTargetEmail(data) {
      this.userData = { ...data };
      this.isShow = false;
    },
    async handleSubmit() {
      if (this.email.length < 3) return false;
      this.$store.commit('SET_USER_DATA', null);
      document.querySelectorAll('.jtk-connector').forEach((e) => e.remove()); // 資料刷新時，需渲染不同畫面，避免上次渲染的資料還在，要先清除再重新渲染
      await this.searchUser({ email: this.email });
      this.isShow = true;
    },
    async getMoreUser() {
      const email = this.email;
      const token = this.userDataList.NextToken;
      if (email && token) {
        await this.searchUser({ email: email, next_token: token });
      }
    },
    sendOTACommand() {
      if (!this.targetOTAPath) return false;
      if (this.sendOTAprocessing === true) return;
      this.sendOTAprocessing = true;

      this.mqttClient.publish(
        this.mqttPublishTopicOTA,
        JSON.stringify({
          Ota: this.targetOTAPath,
          Timestamp: Math.round(new Date() / 1000),
        })
      );
      setTimeout(() => {
        this.sendOTAprocessing = false;
      }, 3000);
    },
    closeHandler() {
      this.show = false;
      this.deviceData = null;
      this.showMap = false;
      this.mqttLogs = [];
      document.body.classList.remove('over-flow-hidden');
      // this.showPlumb()
    },
    DeviceDataHandle(id) {
      let deviceType = [
        '',
        'Air Condition',
        'Dehumidifier',
        'Heat Exchange',
        'AQI',
      ];
      let tmp = [];
      this.getDeviceData.forEach(function(item) {
        if (item.CompID === id) {
          tmp = item;
        }
      });
      tmp.CompID = tmp.CompID.replace('dev#', '');
      let aaa = tmp.CompID.split('_');
      tmp.ThingName = aaa[0];
      tmp.macAddress = aaa[1];
      // console.log('tmp.CreationDate:' + tmp.CreationDate)
      tmp.Creation = moment
        .unix(tmp.CreationDate)
        .format('YYYY-MM-DD HH:mm:ss');
      // console.log('tmp.UpdateDate:' + tmp.UpdateDate)
      tmp.Update = moment.unix(tmp.UpdateDate).format('YYYY-MM-DD HH:mm:ss');
      tmp.LastAccessTime = moment(tmp.LastAccessTime).format(
        'YYYY-MM-DD HH:mm:ss'
      );
      tmp.location = [tmp.GeoLoc.Latitude, tmp.GeoLoc.Longitude];
      tmp.DType = deviceType[tmp.DeviceType];
      tmp.FId = 'N/A';
      tmp.FirmwareVersion = 'N/A';
      this.center = tmp.location;
      this.deviceData = tmp;

      this.getDeviceShadow(tmp.CompID);
      this.mqttSubscribe(tmp.CompID);

      this.show = true;
      //開啟modal時防止背景滾動
      document.body.classList.add('over-flow-hidden');
    },

    async handleAlarm() {
      this.errors = [];

      if (!this.UsageEtime) {
        this.errors.push('Data Usage Start Time required.');
      }
      if (!this.UsageStime) {
        this.errors.push('Data Usage End Time required.');
      }
      if (this.startTime > this.endTime) {
        this.errors.push(
          'Data Usage End Time should not be before Start Time.'
        );
      }

      if (this.errors.length > 0) {
        return;
      }

      this.$store.commit('SET_LOADING', true);
      try {
        const instance = await https.axiosInstance();
        const path = `/data_usage/device?thing_name=${encodeURIComponent(
          this.deviceData.ThingName + '_' + this.deviceData.macAddress
        )}&start_time=${encodeURIComponent(
          this.UsageStime
        )}&end_time=${encodeURIComponent(this.UsageEtime)}`;
        const query_response = await instance.get(path);
        const queryId = query_response.data.QueryId;
        await sleep(5000);
        const url_path = `/data_usage/device/results?query_id=${encodeURIComponent(
          queryId
        )}`;
        const response = await https.pollingGet(url_path);
        if (response && response.data) {
          this.dataUsage = response.data;
        } else {
          alert('exceed retry count');
        }
      } catch (error) {
        let responseContent = '';
        if (error.response !== undefined) {
          responseContent = '\n' + JSON.stringify(error.response.data);
        }
        alert('Error: ' + error.message + responseContent);
      }
      this.$store.commit('SET_LOADING', false);
    },

    handleScroll: function() {
      if (this.scTimer) return;
      this.scTimer = setTimeout(() => {
        this.scY = window.scrollY;
        clearTimeout(this.scTimer);
        this.scTimer = 0;
      }, 100);
    },
    toTop: function() {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    },
  },
  computed: {
    ...mapGetters({
      HostUser: 'getHostUserData',
      userDataList: 'getUserData',
      getDeviceData: 'getDeviceData',
      groupData: 'getGroupData',
      regionData: 'getRegionData',
      deviceShadowData: 'getDeviceShadowData',
      mobileData: 'getMobileData',
      isLoading: 'isLoading',
      subuserData: 'getSubuserData',
      mqttCredentialsData: 'getMqttCredentialsData',
      userPermissionsData: 'getUserPermissionsData',
    }),
    deviceName() {
      let obj = {};
      for (let item of this.getDeviceData) {
        let id = item.CompID.replace('dev#', '');
        let name = item.DeviceName;
        obj[id] = name;
      }
      return obj;
    },
    ShadowData() {
      const ary = [];
      let actionIndex = [];
      if (Object.keys(this.deviceShadowData).length > 0) {
        actionIndex = this.deviceShadowData.conditions.state.reported.Index;
      }
      actionIndex.forEach((index) => {
        const searchName = 'conditions-' + index;
        for (const [key, value] of Object.entries(this.deviceShadowData)) {
          if (key === searchName) {
            if (!value.state.reported.Schedule) return false;
            const object = {};
            object.days = value.state.reported.Schedule.Days;
            const time = value.state.reported.Schedule.Time;
            time.forEach((item, index) => {
              if (item < 10) {
                time[index] = '0' + item;
              }
            });
            object.time = '2022-06-29 ' + time[0] + ':' + time[1];
            object.time = moment(object.time)
              .add(8, 'h')
              .format('HH:mm');
            const ExecAction = value.state.reported.ExecutionsIndex[0];
            const ExecValue = this.deviceShadowData['executions-' + ExecAction];
            object.action = ExecValue.state.reported.Control.Switch;
            if (object.action === 1) {
              object.icon = 'lightbulb-fill';
              object.color = 'open';
            } else {
              object.icon = 'lightbulb-off-fill';
              object.color = 'close';
            }
            ary.push(object);
          }
        }
      });
      console.log(ary);
      return ary;
    },
    ShadowInfo() {
      const info = {};
      Object.entries(this.deviceShadowData).forEach((e) => {
        if (e[0] === 'info') {
          for (const [key, value] of Object.entries(e[1].state.reported)) {
            if (key === 'GoogleHome' || key === 'identityID') continue;
            info[key] = value;
            info.icon = value ? 'bell-fill' : 'bell-slash-fill';
          }
        }
      });
      return info;
    },
    MobileData() {
      let data = {};
      let ios = [];
      let android = [];

      this.mobileData?.forEach((item) => {
        let tmp = item.CompID.replace('mob#', '');
        let deviceType = tmp.split('#');
        let obj = {};
        obj.AppVersion = item.AppVersion;
        obj.SnsEnable = item.SnsEnable;
        obj.DeviceModel = item.DeviceModel;
        obj.sort = item.UpdateDate;
        obj.UpdateDate = moment.unix(item.UpdateDate).format('YYYY-MM-DD');
        obj.SnsEndpoint = item.SnsEndpoint;
        obj.DeviceToken = deviceType[1];
        if (deviceType[0] === 'IOS') {
          ios.push(obj);
        } else if (deviceType[0] === 'ANDROID') {
          android.push(obj);
        }
      });
      android.sort(function(a, b) {
        return b.sort - a.sort;
      });
      data.ios = ios;
      data.android = android;
      return data;
    },
    subUser() {
      let data = [];
      this.subuserData.forEach((item) => {
        let obj = {};
        obj.UserID = item.UserID;
        obj.SubUserEmail = item.SubUserEmail;
        obj.SubUserFirstName = item.SubUserFirstName;
        obj.SubUserLastName = item.SubUserLastName;
        obj.Update = moment.unix(item.UpdateDate).format('YYYY-MM-DD HH:mm:ss');
        obj.Creation = moment
          .unix(item.CreationDate)
          .format('YYYY-MM-DD HH:mm:ss');
        data.push(obj);
      });
      return data;
    },
    mqttPublishTopicRegReq() {
      return this.userData.EnableQAMode
        ? `qa/${this.userid}/${this.mqttThingName}/registration/request`
        : `${this.userid}/${this.mqttThingName}/registration/request`;
    },
    mqttPublishTopicOTA() {
      return this.userData.EnableQAMode
        ? `qa/${this.userid}/${this.mqttThingName}/ota/request`
        : `${this.userid}/${this.mqttThingName}/ota/request`;
    },
    mqttSubscribeTopicRegResp() {
      console.log(this.mqttThingName);
      return this.userData.EnableQAMode
        ? `qa/${this.userid}/${this.mqttThingName}/registration/response`
        : `${this.userid}/${this.mqttThingName}/registration/response`;
    },
    mqttSubscribeTopic() {
      return this.userData.EnableQAMode
        ? `qa/${this.userid}/${this.mqttThingName}/+/response`
        : `${this.userid}/${this.mqttThingName}/+/response`;
    },
    checkDeviceStatus() {
      return (
        this.userPermissionsData &&
        this.userPermissionsData['Auth'] &&
        this.userPermissionsData['Auth'].includes('mqtt_credentials')
      );
    },
  },
  created() {
    window.addEventListener('resize', this.repaintPlumb);
  },
  mounted() {
    if (
      this.userPermissionsData &&
      this.userPermissionsData['Auth'] &&
      this.userPermissionsData['Auth'].includes('mqtt_credentials')
    ) {
      this.getMqttCredentials();
      this.mqttConnect();
    }
    this.email = this.userData ? this.userData.Email : '';
    if (this.email) this.showPlumb(); // 換頁回來時，重新連線
    window.addEventListener('scroll', this.handleScroll);

    // 等地圖創建後執行
    // this.$nextTick(() => {
    //   // 獲得目前位置
    //   navigator.geolocation.getCurrentPosition((position) => {
    //     // console.log(position)
    //     const p = position.coords
    //     // 將中心點設為目前的位置
    //     this.center = [p.latitude, p.longitude]
    //     // 將目前的位置的標記點彈跳視窗打開
    //     this.$refs.location.mapObject.openPopup()
    //   })
    // })
  },
  unmount() {
    window.removeEventListener('resize', this.repaintPlumb);
    window.removeEventListener('scroll', this.handleScroll);
  },
};
</script>

<style scoped>
/* * {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  list-style: none;
} */

:deep .form-control.input {
  border: 1px solid #eeeeee;
  flex-grow: 1;
  padding-left: 1rem;
  padding-top: 8px;
  padding-bottom: 8px;
}

.over-flow-hidden {
  overflow: hidden;
}

.me-1 {
  margin-right: 1rem;
}

.ms-1 {
  margin-left: 1rem;
}

.mb-2 {
  margin-bottom: 2rem;
}

h1 {
  margin-bottom: 2rem;
  color: #767675;
}

.h2 {
  width: 100%;
  text-align: left;
  margin-bottom: 10px;
  color: #be4702;
}

/* we will explain what these classes do next! */
.v-enter-active,
.v-leave-active {
  transition: opacity 0.3s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}

.userinfo,
.subUser {
  position: relative;
  min-width: 100%;
  background-color: #f3f3f3;
}

#UserData_m,
#Device_m {
  display: none;
}

.shadowinfo {
  background-color: #f3f3f3;
}

.userinfo table,
.shadowinfo table,
.subUser table {
  text-align: left !important;
  padding: 10px;
  border-collapse: collapse;
  width: 100%;
  color: #6e6767;
}

.subUser table {
  width: 100%;
  /* display: block; */
  overflow-x: auto;
  white-space: nowrap;
}

.subUser table th,
.subUser table td {
  text-align: center;
  padding: 5px;
  border-right: 1px solid #eeeeee;
  border-bottom: 1px solid #eeeeee;
}

.userinfo table tr {
  border-bottom: 1px solid #eeeeee;
}
.userinfo table tr td:nth-child(odd),
.shadowinfo table tr td:nth-child(odd) {
  color: rgba(252, 111, 17, 0.788);
  font-weight: bold;
  padding: 5px 10px;
}

.subUser table th {
  color: rgba(252, 111, 17, 0.788);
  padding-left: 5px;
  border-bottom: 1px solid #eeeeee;
  height: 40px;
}

.subUser table tr td {
  padding-left: 5px;
  height: 40px;
}

.userinfo table tr td {
  height: 40px;
  word-break: break-all;
}

.inputArea {
  position: relative;
}

.infobtn {
  outline: none;
  border: none;
  padding: 5px 8px;
  line-height: 20px;
  border-radius: 10px;
  color: #ffffff;
  background-color: #4e70ad;
  box-shadow: 0px 2px 0 #2a539f;
  position: absolute;
  right: 12%;
  top: 200%;
  cursor: pointer;
  z-index: 9;
}

.emailinput {
  outline: none;
  height: 35px;
  border: 1px solid #eeeeee;
  padding-left: 10px;
  width: 250px;
  border-radius: 10px;
  position: relative;
  z-index: 2;
}

.otainput {
  outline: none;
  height: 35px;
  border: 1px solid #eeeeee;
  padding-left: 10px;
  width: 400px;
  border-radius: 10px;
  position: relative;
  z-index: 2;
}

.searchBtn {
  cursor: pointer;
  border: none;
  outline: none;
  height: 35px;
  padding: 0 20px 0 30px;
  border-radius: 10px;
  margin-left: -20px;
  transform: translateY(-1px);
}

.top {
  width: 100%;
  padding: 20px;
  margin: 0auto 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
}
.top ul {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-content: center;
  margin-top: 5rem;
}
.top ul li {
  width: 30%;
}

#container {
  display: flex;
  justify-content: space-between;
  align-content: center;
  width: 100%;
  /* min-height: 350px; */
  margin: 1rem auto;
  position: relative; /*一定加上這句，否則連線位置發生錯亂*/
}

.position-relative {
  position: relative;
}

.region,
.left,
.right {
  width: 33%;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  position: relative;
}

.regionList li:hover > div.regionInfo {
  display: block;
}

.regionInfo {
  display: none;
  background-color: #dff8ff;
  width: 120%;
  padding: 10px;
  position: absolute;
  left: 101%;
  top: -50%;
  border: 2px solid #0d9ac5;
  z-index: 9;
  border-radius: 15px;
}
.regionInfo p,
.groupInfo p {
  text-align: center;
  font-size: 1rem;
  margin: 10px 0;
}

.groupList li:hover > div.groupInfo {
  display: block;
}
.groupInfo {
  display: none;
  background-color: #fffede;
  width: 120%;
  padding: 10px;
  position: absolute;
  right: 101%;
  top: -50%;
  border: 2px solid #ffe97a;
  z-index: 9;
  border-radius: 15px;
}

.top li,
.region li,
.left li,
.right li {
  list-style-type: none;
  width: 30%;
  border-radius: 4px;
  border: 1px solid #ccc;
  margin: 0 auto 10px;
  padding: 8px 5px;
  cursor: pointer;
  min-width: 80px;
  min-height: 45px;
  word-break: break-all;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.region li:first-child,
.left li:first-child,
.right li:first-child {
  background-color: #ffffff;
  border-radius: 0;
  border: none;
  border-bottom: 1px solid #767675;
  margin-bottom: 15px;
  cursor: initial;
}

.left li:hover:not(:first-child) {
  border: 2px solid #28d4da;
}

.top li {
  color: rgb(253, 105, 36);
  font-weight: 700;
  font-size: 1.2rem;
  border: none;
  border-radius: 0;
}

.left li {
  background: #f1c4c4;
}
.right li {
  background: #fffd90;
}

.region li {
  background-color: rgb(134, 219, 245);
}

.nodata {
  font-weight: bold;
  background-color: #fff !important;
  border: none !important;
  text-align: center;
  color: #000000 !important;
}

.legend {
  display: flex;
  align-content: center;
}
.legend .item {
  margin: 0 10px;
}
.ex {
  display: inline-block;
  width: 35px;
  height: 15px;
  margin: 0 5px;
  border: 1px solid #cccccc;
  border-radius: 4px;
}

.deviceData {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  margin: 1rem;
  background-color: #e49d9d;
}

.cancel {
  width: 35px;
  height: 35px;
  background-color: #fff;
  border-radius: 50%;
  position: relative;
  margin: 1rem;
  cursor: pointer;
}

.modal {
  /* display: none; Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 99; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100vh; /* Full height */
  /* overflow: hidden; Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
  display: flex;
  align-items: center;
}

/* Modal Content/Box */
.modal-content {
  border-radius: 10px;
  height: 95%;
  background-color: #fefefe;
  margin: 0 auto; /* 15% from the top and centered */
  /* padding: 5px; */
  border: 1px solid #888;
  width: 90%; /* Could be more or less, depending on screen size */
  box-shadow: 5px 8px 8px #888888;
  position: relative;
  overflow-y: auto;
}

.modaltop {
  width: 90%;
  margin: 0 auto;
  padding: 1rem 0;
}

.modal-content h2 {
  color: #2a539f;
  font-size: 2rem;
  margin-bottom: 2rem;
}

.modal-content h3 {
  text-align: left;
  font-size: 1.5rem;
  color: #ff71e0;
}

.modal-content .userinfo {
  margin-top: 1%;
  width: 100%;
}

/* The Close Button */
.close {
  color: #cdc5c5;
  position: absolute;
  font-size: 2rem;
  font-weight: bold;
  z-index: 1;
  right: 1%;
  top: 10px;
  transition: all 0.3s;
}

.close:hover,
.close:focus {
  color: #a39a9a;
  text-decoration: none;
  cursor: pointer;
}

.checkstatus {
  color: #fff;
  border-radius: 5px;
  padding: 10px;
  cursor: pointer;
  transition: all 0.3s ease;
  position: relative;
  display: inline-block;
  box-shadow: inset 2px 2px 2px 0px rgb(255 255 255 / 50%),
    7px 7px 20px 0px rgb(0 0 0 / 10%), 4px 4px 5px 0px rgb(0 0 0 / 10%);
  outline: none;
  background: #00ccff;
  border: none;
  z-index: 1;
}
.checkstatus:disabled,
.checkstatus:disabled:hover:after,
.checkstatus:disabled:after,
.checkstatus:disabled:active {
  background: #7d7a79;
  top: 0;
}

.checkstatus:hover {
  color: rgb(46, 46, 46);
}

.checkstatus:hover:after {
  top: auto;
  bottom: 0;
  height: 100%;
}
.checkstatus:after {
  position: absolute;
  content: '';
  width: 100%;
  height: 0;
  top: 0;
  left: 0;
  z-index: -1;
  border-radius: 5px;
  /* background-color: #eaf818; */
  /* background-image: linear-gradient(315deg, #eaf818 0%, #f6fc9c 74%); */
  background-color: rgb(0 149 255);
  background-image: linear-gradient(315deg, #00ccff 0%, #5bd5e9 74%);
  box-shadow: inset 2px 2px 2px 0px rgb(255 255 255 / 50%);
  transition: all 0.3s ease;
}

.checkstatus:active {
  top: 2px;
}

#map {
  border: 2px solid #999999;
  width: 90%;
  height: 78%;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto auto 30px auto;
  z-index: 99;
}

.shadow {
  margin-top: 1%;
  box-shadow: none !important;
}
.shadow table {
  /* text-align: center;
  padding: 10px; */
  /* display: block; */
  border-collapse: collapse;
  width: 100%;
  overflow-x: auto;
  white-space: nowrap;
}

.schedule {
  margin: 1rem 0;
}

.shadow .schedule table tr:nth-child(1) {
  border-bottom: 1px solid #d6d6d6;
  margin-bottom: 10px;
}

.shadow table td {
  padding: 10px;
  word-break: break-all;
}

.shadow table .th {
  border-bottom: 1px solid #000000;
}

.info {
  text-align: left;
}

.info span {
  display: inline-block;
  width: 250px;
}
.info .shadowKey {
  color: coral;
}

.search input {
  outline: none;
  border: 1px solid #c3c3c3;
  height: 30px;
  line-height: 30px;
  width: auto !important;
  padding: 0 5px;
  border-radius: 3px;
  background: #fff;
}

.mobileDiv {
  display: flex;
  height: 500px;
  /* overflow-y: auto; */
  padding: 1rem;
}

.mobileTable {
  flex: 0 0 50%;
  height: 100%;
  overflow-y: auto;
}

.mobileDiv table {
  table-layout: fixed;
  width: 96%;
  /* width: 40%; */
  border: 1px solid #000000;
  border-collapse: collapse;
}

.mobileDiv table tr:nth-child(1) td {
  position: sticky;
  top: 0px;
  background-color: rgb(230, 230, 230);
  z-index: 2;
}

.mobileDiv table tr:nth-child(2) td {
  position: sticky;
  top: 39px;
  background-color: rgb(230, 230, 230);
  z-index: 2;
}

.mobileDiv table td {
  height: 40px;
  border: 1px solid #d9d9d9;
  word-wrap: break-all;
}

.DeviceType {
  position: relative;
  width: 25px;
}

/* .statusDiv {
  display: flex;
  justify-content: center;
  align-items: center;
} */
.statusDiv .status {
  display: inline-block;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  margin: 0 8px 0 0;
}

.statusDiv .status.online {
  background-image: linear-gradient(to top, #0ba360 0%, #3cba92 100%);
}

.statusDiv .status.offline {
  background: linear-gradient(
      to bottom,
      rgba(255, 255, 255, 0.15) 0%,
      rgba(0, 0, 0, 0.15) 100%
    ),
    radial-gradient(
        at top center,
        rgba(255, 255, 255, 0.4) 0%,
        rgba(0, 0, 0, 0.4) 120%
      )
      #989898;
  background-blend-mode: multiply, multiply;
}

.statusDiv .text {
  display: inline-block;
}

.statusDiv .text span {
  font-size: 0.8rem;
  color: #6e6767;
  font-style: italic;
}

#Notification tr td:first-child {
  width: 25%;
}

.showMap,
.showMQTTLog,
.showOTA {
  background-image: linear-gradient(#0dccea, #0d70ea);
  border: 0;
  border-radius: 4px;
  /* box-shadow: rgba(0, 0, 0, 0.3) 0 5px 15px; */
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  font-family: Montserrat, sans-serif;
  font-size: 0.9em;
  margin: 5px;
  padding: 10px 15px;
  text-align: center;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

#pagetop {
  cursor: pointer;
  position: fixed;
  right: 5%;
  bottom: 1%;
  background: #62eaf152;
  width: 35px;
  height: 35px;
  border-radius: 5px;
  box-shadow: 0 3px 5px #75ccd5;
  animation: topjump 2s ease-in-out infinite;
  z-index: 2;
}

@keyframes topjump {
  0%,
  20%,
  53%,
  80%,
  100% {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    transform: translate(0, 0);
  }
  40%,
  43% {
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    transform: translate(0, -30px);
  }

  70% {
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    transform: translate(0, -15px);
  }
  90% {
    transform: translate(0, -4px);
  }
}

/* flatpickr css */

.flatpickr-time input:hover,
.flatpickr-time .flatpickr-am-pm:hover,
.flatpickr-time input:focus,
.flatpickr-time .flatpickr-am-pm:focus {
  background: #1bbc9b;
  color: #ffffff;
}
.flatpickr-day.today {
  border-color: #1bbc9b;
}

/* boostrap icon css */

.bi {
  color: rgb(189, 189, 189);
}
.bi--variant-open {
  color: #ffe97a;
}

.bi--variant-close {
  color: rgb(207 124 39);
}

.bi--variant-check-green {
  color: #1dda26;
}

::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}

::-webkit-scrollbar-thumb {
  border-radius: 1em;
  background-color: rgba(50, 50, 50, 0.3);
}

::-webkit-scrollbar-track {
  border-radius: 1em;
  background-color: rgba(50, 50, 50, 0.1);
}

pre {
  text-align: left;
}

.userList {
  max-height: 0;
  transition: max-height 0.3s;
  overflow: hidden;
}
.userList.active {
  max-height: 500px;
  overflow: auto;
}
.mqtt-topic-box {
  background-color: rgb(189, 189, 189);
}
@media screen and (max-width: 1200px) {
  #UserData_m,
  #Device_m {
    display: block;
  }
  #UserData_pc,
  #Device_pc {
    display: none;
  }

  .mobileDiv {
    display: block;
    overflow-y: auto;
    padding-top: 0;
    height: 400px;
  }
  .mobileTable {
    height: initial;
    overflow-y: unset;
  }
  .shadow table,
  .subUser table {
    display: block;
  }
  .mobileDiv table {
    width: 100%;
    margin: 10px 0 !important;
  }
  .regionInfo,
  .groupInfo {
    width: 200%;
  }

  #Notification tr td:first-child {
    width: auto;
  }
}
</style>
